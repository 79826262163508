const images = $('.course-item-img');
images.map(function (id, image) {
    image.src = image.dataset.image;
});

let params = new URLSearchParams(window.location.search);

const courseContainer = $('#courses_container');
const paginationContainer = $('#pagination_container');

setUp(true);

function fieldUpdated(key, value, isArray) {
    const currentValue = isArray ? params.getAll(key) : params.get(key);
    if (currentValue !== value) {
        const isEmpty = isArray ? !value.length : !value;
        if (isEmpty) {
            params.delete(key);
        } else {
            params.set(key, value);
        }

        if (key !== 'page') {
            params.delete('page');
        }
        const queryString = params.toString();

        loadData(queryString)
    }
}

window.onpopstate = state => {
    params = new URLSearchParams(window.location.search);
    loadData(window.location.search);
    setUp(false);
};

let isLoading = false;
function loadData(queryString) {
    if (!isLoading) {
        isLoading = true;
        $('.loader').addClass('show');

        const dataUrl = new URL(baseUrl);
        const url = new URL(window.location);
        url.search = queryString;
        dataUrl.search = queryString;

        window.history.pushState('', '', url);

        axios.get(dataUrl)
            .then(response => {
                let coursesContent;
                if (response.data.courses) {
                    coursesContent = '<div class="marketplace-content">' + response.data.courses + '</div>';
                } else {
                    coursesContent = '<h2 class="no-course">' + window.notFoundMessage + '</h2>';
                }

                courseContainer.html(coursesContent);
                paginationContainer.html(response.data.pagination);

                const images = $('.course-item-img');
                images.map(function (id, image) {
                    image.src = image.dataset.image;
                });
                setUpPages(true);
            })
            .finally(() => {
                $('.loader').removeClass('show');
                isLoading = false;
            })
    }
}

function setUp(full) {
    setUpSearch(full);
    setUpPrices(full);
    setUpCategories(full);
    setUpAuthors(full);
    setUpSchools(full);
    setUpSort(full);
    setUptypeFilter(full);
    setUpClear(full);
    setUpPages(full);
}

function setUpSearch(full) {
    const searchInput = $('#search-input');
    searchInput.val(params.get('search'));

    if (full) {
        $('.search-form').on('submit', function (e) {
            e.preventDefault();
        });

        searchInput.on('change', function (e) {
            fieldUpdated('search', e.target.value, false);
        });
    }
}

function setUpPrices(full) {
    const freeSwitch = $('#free_switch');
    const priceRange = $('#price_range');
    const minPrice = $('#min_price');
    const maxPrice = $('#max_price');

    if (params.get('is_free')) {
        freeSwitch.prop('checked', true);
        priceRange.addClass('d-none');
    } else {
        freeSwitch.prop('checked', false);
        priceRange.removeClass('d-none');
    }

    minPrice.val(params.get('min_price'));
    maxPrice.val(params.get('max_price'));

    if (full) {
        freeSwitch.on('change', function () {
            const isChecked = $(this).prop('checked');
            priceRange.toggleClass('d-none', isChecked);
            fieldUpdated('is_free', isChecked, false);
        });

        minPrice.on('change', function (e) {
            fieldUpdated('min_price', e.target.value);
        });
        maxPrice.on('change', function (e) {
            fieldUpdated('max_price', e.target.value);
        });
    }
}

function setUpCategories(full) {
    const currentCategories = params.has('categories')
        ? params.get('categories').split(',').filter(id => id)
        : [];
    const categoryInputs = $('#categories_dropdown input');
    categoryInputs.each(function (key, input) {
        $(this).prop('checked', currentCategories.includes(input.value));
    });

    if (full) {
        categoryInputs.on('change', function (e) {
            const ids = $('#categories_dropdown input:checked').map(function(key, input) {
                return input.value;
            }).toArray();

            fieldUpdated('categories', ids, true);
        });
    }
}

function setUpAuthors(full) {
    const currentAuthors = params.has('authors')
        ? params.get('authors').split(',').filter(id => id)
        : [];
    const authorInputs = $('#authors_dropdown input');
    authorInputs.each(function (key, input) {
        $(this).prop('checked', currentAuthors.includes(input.value));
    });

    if (full) {
        authorInputs.on('change', function (e) {
            const ids = $('#authors_dropdown input:checked').map(function(key, input) {
                return input.value;
            }).toArray();

            fieldUpdated('authors', ids, true);
        });
    }
}

function setUpSchools(full) {
    const currentSchools = params.has('schools')
        ? params.get('schools').split(',').filter(id => id)
        : [];
    const schoolInputs = $('#schools_dropdown input');
    schoolInputs.each(function (key, input) {
        $(this).prop('checked', currentSchools.includes(input.value));
    });

    if (full) {
        schoolInputs.on('change', function (e) {
            const ids = $('#schools_dropdown input:checked').map(function(key, input) {
                return input.value;
            }).toArray();

            fieldUpdated('schools', ids, true);
        });
    }
}

function setUpSort(full) {
    const currentSort = params.get('sort') || 'FEATURED';
    const sortButtons = $('.sort_option');

    sortButtons.each(function (key, input) {
        if (input.dataset.value === currentSort) {
            $('.current_sort_label').text(input.textContent);
            input.classList.add('active');
        } else {
            input.classList.remove('active');
        }
    });


    if (full) {
        sortButtons.on('click', function (e) {
            const newSort = e.currentTarget.dataset.value;
            fieldUpdated('sort', newSort, false);

            sortButtons.each(function (key, input) {
                if (input.dataset.value === newSort) {
                    $('.current_sort_label').text(input.textContent);
                    input.classList.add('active');
                } else {
                    input.classList.remove('active');
                }
            });
        });
    }
}

function setUptypeFilter(full) {
    const currentType = params.get('type') || 'ALL';
    const typeButtons = $('.type_option');

    typeButtons.each(function (key, input) {
        if (input.dataset.value === currentType) {
            $('.current_type_label').text(input.textContent);
            input.classList.add('active');
        } else {
            input.classList.remove('active');
        }
    });


    if (full) {
        typeButtons.on('click', function (e) {
            const newType = e.currentTarget.dataset.value;
            fieldUpdated('type', newType, false);

            typeButtons.each(function (key, input) {
                if (input.dataset.value === newType) {
                    $('.current_type_label').text(input.textContent);
                    input.classList.add('active');
                } else {
                    input.classList.remove('active');
                }
            });
        });
    }
}

function setUpPages(full) {
    const pageLinks = $('.new-pagination a');

    pageLinks.on('click', function (e) {
        e.preventDefault();
        const newPage = e.currentTarget.dataset.page;

        fieldUpdated('page', newPage, false);
    });
}

function setUpClear(full) {
    if (full) {
        $('.clean_filters').on('click', function () {
            window.location.search = '';
        });
    }
}
